import { Github } from "@heroicons/react/24/outline";
import React from "react";

export default function Footer() {
  return (
    <>
      <div className="bg-black h-max py-20">
        <div className="bg-black text-white">
          <div>
            <nav className="space-x-20">
              <a href="#about-me">About</a>
              <a href="#recent-projects">Works</a>
              <a>Contact</a>
            </nav>
          </div>
          <div className="pt-10">
            <a href="mailto:pdigitalcrafts@protonmail.com">
              pdigitalcrafts@protonmail.com
            </a>
            <br></br>
            <a href="tel:8177524754">(817)752-4754</a>
          </div>
          <div className="display flex justify-center mt-10 ">
            <a
              href="https://github.com/puran05"
              class="ml-6 text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">GitHub</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  fill-rule="evenodd"
                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/in/puran-subedi-aa3544231/"
              class="ml-6 text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">LinkedIn</span>
              <svg
                class="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11 19h-3v-9h3v9zm-1.5-10.256c-.966 0-1.75-.784-1.75-1.75s.784-1.75 1.75-1.75 1.75.784 1.75 1.75-.784 1.75-1.75 1.75zm13.5 10.256h-3v-4.5c0-1.104-.896-2-2-2s-2 .896-2 2v4.5h-3v-9h3v1.235c.876-.805 2.124-1.235 3.5-1.235 2.485 0 4.5 2.015 4.5 4.5v4.5z" />
              </svg>
            </a>
            <a
              href="https://codepen.io/puran05"
              class="ml-6 text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">CodePen</span>
              <svg
                class="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 2c-1.135 0-2.242.322-3.2.921l-7 4.5a5.002 5.002 0 000 8.157l7 4.5a5.993 5.993 0 006.4 0l7-4.5a5.002 5.002 0 000-8.157l-7-4.5a5.993 5.993 0 00-3.2-.921zm0 1.872c.525 0 1.044.13 1.52.37l6.56 4.22a1.995 1.995 0 010 3.076l-6.56 4.22a3.008 3.008 0 01-3.04 0l-6.56-4.22a1.995 1.995 0 010-3.076l6.56-4.22c.476-.24.995-.37 1.52-.37zm0 1.388l-4.92 3.16 4.92 3.16 4.92-3.16-4.92-3.16zm-6.4 4.7l1.86 1.18-1.86 1.18v-2.36zm12.8 0v2.36l-1.86-1.18 1.86-1.18zm-6.4 2.48l-4.92 3.16 4.92 3.16 4.92-3.16-4.92-3.16zm-6.4 3.22l1.86-1.18 1.86 1.18-1.86 1.18-1.86-1.18zm12.8 0l-1.86 1.18-1.86-1.18 1.86-1.18 1.86 1.18z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
