import React, { useEffect, useState } from "react";
import Navigation from "./Navigation";
import Footer from "./Footer";
import img1 from "../images/arte-portfoliopic.webp";
import img2 from "../images/qliquor-portfoliopic.webp";
import img3 from "../images/zoom-portfoliopic.webp";
import opensign from "../images/open_sign.webp";
import displaypic from "../images/display-pic.jpeg";
import { useInView } from "react-intersection-observer";

const Section = ({ children, className = "" }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
    rootMargin: "0px 0px -10% 0px",
  });

  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    if (inView && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [inView, hasAnimated]);

  return (
    <div className={`${className}`} ref={ref}>
      <div
        className={`transition-all duration-700 ${
          hasAnimated
            ? "animate-fadeInUp opactity-100"
            : "opacity-0 translate-y-10"
        }`}
      >
        {children}
      </div>
    </div>
  );
};
export default function Home() {
  return (
    <>
      <Navigation />
      <section className="bg-customPurple">
        <div className="flex flex-col justify-center items-center pt-[40%] pb-[45%] pl-5 pr-5 animate-fadeInUp">
          <h2 className="text-white text-2xl font-bold tracking-wide drop-shadow-sm">
            Get Online with
          </h2>
          <h1 className="text-white text-4xl font-bold tracking-wider drop-shadow-sm ">
            Stunning Websites
          </h1>
          <p className="text-white py-3 tracking-wide drop-shadow-sm">
            Custom wesbites designed to grow your business
          </p>
          <button className="bg-white rounded-lg px-6 py-3 mt-3 font-semibold">
            {" "}
            Get In Touch{" "}
          </button>
        </div>
      </section>

      {/*Business Pitch*/}
      <Section className="bg-gradient-to-b from-customPurple to-black">
        <section>
          <div className="flex flex-col justify-center items-center px-5 animate-fadeInUp">
            <img
              src={opensign}
              alt="laptop image"
              className="h-[200px]  rounded-md"
              loading="eager"
            ></img>
            <h2 className="py-4 pl-4 pr-4 text-left text-2xl font-bold tracking-wide drop-shadow-sm text-white">
              {" "}
              You Run the Business, I'll handle the Pixels{" "}
            </h2>
            <p className="pl-4 pr-4 text-left text-white">
              A great website isn’t just a digital address—it’s your local
              business’s 24/7 salesperson, working around the clock to showcase
              your services and build trust with customers. It boosts your
              visibility in the community, attracts more foot traffic, and opens
              doors to new opportunities. Feel free to read the link below that
              talks more about how a website helps towards the business
            </p>

            <a
              className="bg-customPurple rounded px-5 py-2 mt-3 text-white"
              target="_blank"
              href="https://www.investopedia.com/digital-marketing-and-business-success-8711955"
            >
              {" "}
              Go to article
            </a>
          </div>
        </section>
      </Section>

      {/*Business Pitch*/}
      <Section className="bg-black">
        <section
          className="flex flex-col justify-center items-center py-20"
          id="recent-projects"
        >
          <div className="animate-fadeInUp">
            <h1 className="py-4 pl-4 text-left text-4xl font-bold tracking-wide drop-shadow-sm text-white">
              {" "}
              Recent Projects
            </h1>
            <div className="py-10 flex flex-col ">
              <img
                src={img1}
                alt="laptop image"
                className="h-[200px]  rounded-md"
                loading="lazy"
              ></img>
              <h2 className="pt-4 pl-4 pr-4 text-2xl text-left font-bold tracking-wide drop-shadow-sm text-white">
                {" "}
                Arte Vestire{" "}
              </h2>
              <p className="text-gray-200 text-left pl-4">Web Development </p>
            </div>
            <div className="py-10">
              <img
                src={img2}
                alt="laptop image"
                className="h-[200px] rounded-md"
                loading="lazy"
              ></img>
              <h2 className="pt-4 pl-4 pr-4 text-left text-2xl font-bold tracking-wide drop-shadow-sm text-white">
                {" "}
                Q Liquors{" "}
              </h2>
              <p className="text-gray-200 text-left pl-4">Web Development </p>
            </div>
            <div className="py-10">
              <img
                src={img3}
                alt="laptop image"
                className="h-[200px] rounded-md"
                loading="lazy"
              ></img>
              <h2 className="pt-4 pl-4 pr-4 text-left text-2xl font-bold tracking-wide drop-shadow-sm text-white">
                {" "}
                Zoom Bikes{" "}
              </h2>
              <p className="text-gray-200 text-left pl-4">Web Design </p>
            </div>
          </div>
        </section>
      </Section>

      {/*About me */}
      <Section className="bg-customPurple">
        <section className="py-20 px-5" id="about-me">
          <div className="flex flex-col justify-center items-center animate-fadeInUp">
            <h1 className="py-4 pl-4 text-left text-4xl font-bold tracking-wide drop-shadow-sm text-white">
              {" "}
              About Me
            </h1>
            <img
              src={displaypic}
              alt="laptop image"
              className="h-[200px] rounded-full"
              loading="lazy"
            ></img>
            <p className="pl-4 pr-4 py-5 text-left text-white">
              {" "}
              I’m a web developer specializing in websites and e-commerce
              platforms. With experience helping local businesses, I’m
              passionate about helping owners succeed online. I’ll handle your
              website’s design, hosting, and updates so you can focus on growing
              your business. Let’s connect and get your business thriving
              online!
            </p>
          </div>
        </section>
      </Section>

      {/*Faq*/}
      <Section className="bg-gradient-to-b from-customPurple to-black ">
        <section>
          <div className="animate-fadeInUp">
            <h1 className="text-white text-4xl font-bold tracking-wider drop-shadow-sm pt-[30%]">
              Lets Work Together
            </h1>
            <button className="bg-white rounded px-5 py-2 mt-10 mb-[30%]">
              {" "}
              Get In Touch{" "}
            </button>
          </div>
        </section>
      </Section>
      <section></section>

      {/*FAQ Section*/}
      <Section className="bg-black">
        <main class="mx-auto px-8 py-20" id="faq">
          <h1 className="text-white text-4xl font-bold tracking-wider pb-10 animate-fadeInUp">
            Frequently asked question
          </h1>
          <section className="grid grid-cols-1 gap-y-3 divide-y animate-fadeInUp">
            <details className="group py-1 text-lg">
              <summary className="flex cursor-pointer flex-row text-gray-100 items-center justify-between py-1 font-bold">
                How much does it cost to make a website
                <svg
                  class="h-6 w-6 rotate-0 transform text-gray-400 group-open:rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </summary>
              <p className="text-gray-300 text-left text-sm">
                Price to create a website for your business starts from $190.
                Yes, you read that right. For only $190 you can save your spot
                and be in front of your competitor
              </p>
            </details>

            <details className="group py-1 text-lg">
              <summary className="flex cursor-pointer  flex-row text-gray-100 items-center justify-between py-1 font-bold">
                Can you create mobile-friendly website?
                <svg
                  class="h-6 w-6 rotate-0 transform text-gray-400 group-open:rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </summary>
              <p className="text-gray-300 text-left text-sm">
                Absolutely! I prioritize a mobile-first approach since the
                majority of users access websites via their mobile devices.
                However, rest assured, the site will be fully responsive and
                optimized to perform seamlessly across all devices and screen
                sizes.
              </p>
            </details>
            <details className="group py-1 text-lg">
              <summary className="flex cursor-pointer flex-row text-gray-100 items-center justify-between py-1 font-bold">
                How long does it take to create a website
                <svg
                  class="h-6 w-6 rotate-0 transform text-gray-400 group-open:rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </summary>
              <p className="text-gray-300 text-left text-sm">
                The timeline can vary depending on the project, but it typically
                takes anywhere from 1 to 6 weeks, depending on your specific
                requirements and complexity of the site.
              </p>
            </details>
          </section>
        </main>
      </Section>
      <Footer />
    </>
  );
}
